import React, { Component } from "react";
import PhoneInput from "react-phone-number-input/react-responsive-ui";
import "react-phone-number-input/style.css";
import "react-responsive-ui/style.css";
import "./index.css";

class Phone extends Component {
  constructor(props) {
    super(props);
    console.log("PHONE FIELDS ===== props", props);
    this.state = {
      name: props.field.name,
      phonePlaceholder: "Enter Your Phone Number",
      value: "+49",
    };
  }

  handleChange = (value) => {
    this.setState({ value });
    this.props.form.setFieldValue(this.state.name, value);
  };

  render() {
    const {
      field: { name, ...field },
      form: { touched, errors },
      options,
      label,
      ...rest
    } = this.props;

    const error = errors[name];
    const touch = touched[name];

    return (
      <React.Fragment>
        <PhoneInput
          inputClassName={
            error ? "ts-phone-input is-invalid" : "ts-phone-input "
          }
          placeholder={this.state.phonePlaceholder}
          value={this.state.value}
          onChange={this.handleChange}
        />
        {error && touch ? (
          <div className="bg-danger my-1 p-0 col-12 rounded">
            <p className="text-white p-1 m-0">{error}</p>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default Phone;
